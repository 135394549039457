/**
 * @module js/mpDragAndDropService
 */

import _appCtxSvc from 'js/appCtxService';
import eventBus from 'js/eventBus';
import {renameWidget} from 'js/oPINRQCRenderService'
import mpComposerService from "js/oPINRQuickComposerModule/oPINRQuickComposerService"
let dragEnterName = "";
let dragView = null;
let dragStartName = "";
let msg = "";

function logOp(newMessage) {
    if (newMessage !== msg) {
        msg = newMessage;
        console.log("##DRAG##",newMessage);
    }
}
let composerDragStart = (dnDParams) => {
    if (dnDParams.declViewModel.hasOwnProperty("parentContext") &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("name") &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("splitMode") &&
        dnDParams.declViewModel.parentContext.splitMode === "" &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("theConf") &&
        dnDParams.declViewModel.parentContext.theConf.hasOwnProperty("customConf") &&
        dnDParams.declViewModel.parentContext.theConf.hasOwnProperty("wConf")) {
        dragStartName = dnDParams.declViewModel.parentContext.name;
        dragView = dnDParams.declViewModel;//.parentContext;
        logOp("START for " + dragStartName);
    }
    dragEnterName = "";
};
let composerDragEnd = (dnDParams) => {
    dragView = null;
    dragStartName = "";
    dragEnterName = "";
};
let composerDragEnter = (dnDParams) => {
    if (dnDParams.declViewModel.hasOwnProperty("parentContext") &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("name") &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("splitMode") &&
        dnDParams.declViewModel.parentContext.splitMode === "" &&
        (!dnDParams.declViewModel.parentContext.hasOwnProperty("theConf") ||
        !dnDParams.declViewModel.parentContext.theConf)) {
        dragEnterName = dnDParams.declViewModel.parentContext.name;
        logOp("ENTER for " + dragEnterName);
    }
    else if (!dragStartName){
        composerDragStart(dnDParams)
    }
    return {
        preventDefault: true,
        stopPropagation: true
    };
};
let composerDragLeave = (dnDParams) => {
    if (!dnDParams.targetElement.contains(dnDParams.event.fromElement)) {
        dnDParams.callbackAPIs.highlightTarget({
            isHighlightFlag: false,
            targetElement: dnDParams.targetElement
        });
        logOp("LEAVE for " + dnDParams.declViewModel.parentContext.name);
    }
    return {
        dropEffect: 'none',
        stopPropagation: true
    };
};


let composerDragOver = (dnDParams) => {
    if (dragEnterName === dnDParams.declViewModel.parentContext.name) {
        logOp("OVER for " + dnDParams.declViewModel.parentContext.name);
        dnDParams.callbackAPIs.highlightTarget({
            isHighlightFlag: true,
            targetElement: dnDParams.targetElement
        });
    } else {
        return {
            dropEffect: 'none',
            stopPropagation: true
        };
    }
    return {
        preventDefault: true,
        stopPropagation: true,
        dropEffect: 'copy'
    };
};

let composerDragDrop = (dnDParams) => {
    if (dragView && dnDParams.declViewModel.hasOwnProperty("parentContext") &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("name") &&
        dnDParams.declViewModel.parentContext.hasOwnProperty("splitMode") &&
        dnDParams.declViewModel.parentContext.splitMode === "" &&
        (!dnDParams.declViewModel.parentContext.hasOwnProperty("theConf") ||
        !dnDParams.declViewModel.parentContext.theConf)) {
        logOp("DROP!!! move " + dragView.parentContext.name + " to " + dnDParams.declViewModel.parentContext.name)
        dnDParams.callbackAPIs.highlightTarget({
            isHighlightFlag: false,
            targetElement: dnDParams.targetElement
        });

        let response = renameWidget(dragView.parentContext.theConf, dnDParams.declViewModel.parentContext.name);
        //delete dragView.parentContext.theConf; //remove preview place configuration
        const t = dragView.atomicDataRef.parentContext.getAtomicData();
        delete t.theConf;
        dragView.atomicDataRef.parentContext.setAtomicData(t)
        
        const p = dnDParams.declViewModel.atomicDataRef.parentContext.getAtomicData();
        p.theConf = response.theConf;
        dnDParams.declViewModel.atomicDataRef.parentContext.setAtomicData(p)
        //dnDParams.declViewModel.theConf = response.theConf; //move configuration to new place
        mpComposerService.updateHistory({});
        if (response.modified) {
            _appCtxSvc.updateCtx("viewParList", response.viewParList)
        }
        _appCtxSvc.updateCtx("selectedConf", response.theConf);
        _appCtxSvc.updateCtx("selectedView", dnDParams.declViewModel.parentContext.name);
        eventBus.publish("widgetListConfChanged");
        setTimeout(()=>{
            eventBus.publish("opINRefreshWidgetDrop",{viewName:p.name});
            eventBus.publish("opINRefreshWidgetDrop",{viewName:t.name});
        },0);
        
        dragView = null;
    }
    return {
        stopPropagation: true
    };
};

const exports = {
    composerDragStart: composerDragStart,
    composerDragDrop: composerDragDrop,
    composerDragOver: composerDragOver,
    composerDragLeave: composerDragLeave,
    composerDragEnter: composerDragEnter,
    getEnterName: () => dragEnterName,
    getDragStartName: () => dragStartName,
    composerDragEnd:composerDragEnd

};
export default new Proxy(exports, {
    get(target, prop) {
        if (typeof target[prop] === 'function') {
            return new Proxy(target[prop], {
                apply: (target, thisArg, argumentsList) => {
                    console.debug("####mpDragAndDropService method call: " + prop, argumentsList)
                    return Reflect.apply(target, thisArg, argumentsList);
                }
            });
        } else {
            return Reflect.get(target, prop);
        }
    }
});