// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/oPINEngineeringModule/oPINEngineeringService', () => import('js/oPINEngineeringModule/oPINEngineeringService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "DataSourceType": {
            "displayName": "DataSource Type",
            "dbValue": "{{props.subPanelContext.selection[0]._data.DataSourceType}}",
            "dispValue": "{{ctx.readdableDataSourceTypes[props.subPanelContext.selection[0]._data.DataSourceType]}}"
        },
        "AssetModelOwnership": {
            "displayName": "Asset Model Ownership",
            "dbValue": "{{props.subPanelContext.selection[0]._data.AssetModelOwnership}}",
            "dispValue": "{{ctx.readdableAssetModelsOwnerships[props.subPanelContext.selection[0]._data.AssetModelOwnership]}}"
        }
    },
    "actions": {
        "getSelectedObject": {
            "actionType": "JSFunction",
            "method": "returnSiteData",
            "inputData": {
                "data": "{{data}}",
                "fields": "{{fields}}"
            },
            "deps": "js/oPINEngineeringModule/oPINEngineeringService",
            "outputData": {
                "DataSourceType": "DataSourceType",
                "AssetModelOwnership": "AssetModelOwnership"
            }
        }
    },
    "lifecycleHooks": {
        "onUpdate": [
            {
                "action": "getSelectedObject",
                "observers": [
                    "props.subPanelContext.selection.uid",
                    "props.subPanelContext.selection[0].uid",
                    "props.subPanelContext.selection.length"
                ]
            }
        ]
    },
    "i18n": {
        "siteKey": [
            "oPINEngineeringMessages"
        ],
        "DataSourceType": [
            "oPINEngineeringMessages"
        ],
        "displayName": [
            "oPINEngineeringMessages"
        ],
        "AssetModelsOwnership": [
            "oPINEngineeringMessages"
        ],
        "timeZone": [
            "oPINEngineeringMessages"
        ],
        "Description": [
            "oPINEngineeringMessages"
        ],
        "overview": [
            "oPINEngineeringMessages"
        ]
    },
    "_viewModelId": "oPINEngineeringSitesDetails",
    "_uniqueViewModelId": "oPINEngineeringSitesDetails",
    "ctx": {
        "readdableDataSourceTypes": {
            "type": "object"
        },
        "readdableAssetModelsOwnerships": {
            "type": "object"
        }
    }
};

    /*
    <aw-panel-body>
<aw-command-panel-section width="12" caption="i18n.overview" height="fill" collapsed="disabled">
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.siteKey}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Name}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.displayName}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.DisplayName}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Description}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Description}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.DataSourceType}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{data.DataSourceType.dispValue}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.AssetModelsOwnership}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{data.AssetModelOwnership.dispValue}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.timeZone}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.TimeZone_WinName}}
                <br/>{{props.subPanelContext.selection[0]._data.TimeZone_Description}}</div>
        </div>
    </div>
</aw-command-panel-section>
</aw-panel-body>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanelBody>
        <AwCommandPanelSection width="12" caption={i18n.overview} height="fill" collapsed="disabled">
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.siteKey" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.displayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.DisplayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.DataSourceType" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "data.DataSourceType.dispValue" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.AssetModelsOwnership" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "data.AssetModelOwnership.dispValue" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.timeZone" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.TimeZone_WinName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                        <br>
                        </br>
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.TimeZone_Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
        </AwCommandPanelSection>
    </AwPanelBody>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;