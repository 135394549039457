// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import MomDefaultSublocation from 'viewmodel/MomDefaultSublocationViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_sublocation": {
            "nameToken": "com.siemens.mom.splm.ocin:opInTableListWithSummariesLocations",
            "clientScopeURI": "",
            "viewBase": "{{ctx.opInCurrentPage}}",
            "defaultDisplayMode": "{{ctx.opInDefaultDisplayMode}}",
            "searchControl": "{{ctx.searchControlVisible}}",
            "searchHint": "{{ctx.searchControlHint}}",
            "momEmptyComponentTypeIconName": "{{ctx.opInEmptyComponentTypeIcon}}",
            "momEmptyComponentText": "{{i18n.noDataInfo}}",
            "viewModes": {
                "SummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true,
                    "searchControl": "{{ctx.searchControlVisible}}",
                    "searchHint": "{{ctx.searchControlHint}}"
                },
                "TableSummaryView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": true,
                    "searchControl": "{{ctx.searchControlVisible}}",
                    "searchHint": "{{ctx.searchControlHint}}"
                }
            }
        }
    },
    "i18n": {
        "noDataInfo": [
            "commonUtilsMessages"
        ]
    },
    "_viewModelId": "opInTableListWithSummariesSubLocations",
    "_uniqueViewModelId": "opInTableListWithSummariesSubLocations",
    "ctx": {
        "opInCurrentPage": {
            "type": "object"
        },
        "opInDefaultDisplayMode": {
            "type": "object"
        },
        "searchControlVisible": {
            "type": "object"
        },
        "searchControlHint": {
            "type": "object"
        },
        "opInEmptyComponentTypeIcon": {
            "type": "object"
        },
        "opInSelectionMode": {
            "type": "object"
        }
    }
};

    /*
    <mom-default-sublocation sub-panel-context="{ ...data._sublocation, ...props.subPanelContext, selectionMode: ctx.opInSelectionMode}"></mom-default-sublocation>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <MomDefaultSublocation subPanelContext={AwParseService.instance( "{ ...data._sublocation, ...props.subPanelContext, selectionMode: ctx.opInSelectionMode}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </MomDefaultSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;