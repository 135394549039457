// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {},
    "actions": {},
    "lifecycleHooks": {},
    "i18n": {
        "Name": [
            "oPINEngineeringMessages"
        ],
        "displayName": [
            "oPINEngineeringMessages"
        ],
        "Description": [
            "oPINEngineeringMessages"
        ],
        "overview": [
            "oPINEngineeringMessages"
        ]
    },
    "_viewModelId": "oPINEngineeringRTsDetails",
    "_uniqueViewModelId": "oPINEngineeringRTsDetails",
    "ctx": {}
};

    /*
    <aw-panel-body>
    <aw-command-panel-section width="12" caption="i18n.overview" height="fill" collapsed="disabled">
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Name}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Name}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.displayName}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.DisplayName}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Description}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Description}}</div>
        </div>
    </div>
</aw-command-panel-section>
</aw-panel-body>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanelBody>
        <AwCommandPanelSection width="12" caption={i18n.overview} height="fill" collapsed="disabled">
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.displayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.DisplayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
        </AwCommandPanelSection>
    </AwPanelBody>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;