// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
import AwRow from 'viewmodel/AwRowViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const DivVisibleWhen = VisibleWhen('div');
DivVisibleWhen.displayName = 'DivVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/oPINEngineeringModule/oPINEngineeringService', () => import('js/oPINEngineeringModule/oPINEngineeringService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "Color": {
            "displayName": "Color",
            "dbValue": "{{props.subPanelContext.selection[0]._data.Color}}",
            "dispValue": "{{props.subPanelContext.selection[0]._data.Color}}"
        }
    },
    "actions": {
        "getSelectedObject": {
            "actionType": "JSFunction",
            "method": "returnColor",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/oPINEngineeringModule/oPINEngineeringService",
            "outputData": {
                "Color": "Color"
            }
        }
    },
    "lifecycleHooks": {
        "onUpdate": [
            {
                "action": "getSelectedObject",
                "observers": [
                    "props.ctx.reasonTreeItemSelected._id",
                    "props.subPanelContext.selection.uid",
                    "props.subPanelContext.selection[0].uid",
                    "props.subPanelContext.selection.length"
                ]
            }
        ]
    },
    "onEvent": [
        {
            "eventId": "appCtx.*",
            "condition": "name === 'reasonTreeItemSelected' && ctx.reasonTreeItemSelected !== null && ctx.reasonTreeItemSelected !== undefined",
            "action": "getSelectedObject"
        }
    ],
    "i18n": {
        "TRUE": [
            "commonUtilsMessages"
        ],
        "FALSE": [
            "commonUtilsMessages"
        ],
        "Name": [
            "oPINEngineeringMessages"
        ],
        "displayName": [
            "oPINEngineeringMessages"
        ],
        "Description": [
            "oPINEngineeringMessages"
        ],
        "color": [
            "oPINEngineeringMessages"
        ],
        "isstop": [
            "oPINEngineeringMessages"
        ],
        "mustBeJustified": [
            "oPINEngineeringMessages"
        ],
        "useForJustify": [
            "oPINEngineeringMessages"
        ],
        "overview": [
            "oPINEngineeringMessages"
        ]
    },
    "_viewModelId": "oPINEngineeringRTIsDetails",
    "_uniqueViewModelId": "oPINEngineeringRTIsDetails",
    "ctx": {
        "reasonTreeItemSelected": {
            "type": "object"
        }
    }
};

    /*
    <aw-panel-body>
    <aw-command-panel-section width="12" caption="i18n.overview" height="fill" collapsed="disabled">
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Name}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Name}}</div>
            </div>
        </div>
        <aw-break></aw-break>
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.displayName}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.DisplayName}}</div>
            </div>
        </div>
        <aw-break></aw-break>
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Description}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Description}}</div>
            </div>
        </div>
        <aw-break></aw-break>
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.color}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                <aw-row height="1.3f" class="sw-property-val" color="{{'#'+props.subPanelContext.selection[0]._data.Color}}"></aw-row>
            </div>
        </div>
        <aw-break></aw-break>
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.isstop}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer">
                <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.IsStop">{{i18n.TRUE}}</div>
                <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.IsStop">{{i18n.FALSE}}</div>
            </div>
        </div>
        <aw-break></aw-break>
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.mustBeJustified}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer">
                <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.MustBeJustified">{{i18n.TRUE}}</div>
                <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.MustBeJustified">{{i18n.FALSE}}</div>
            </div>
        </div>
        <aw-break></aw-break>
        <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly" visible-when="conditions.useForJustifyEnabled">
            <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.useForJustify}}</div>
            <div class="aw-widgets-propertyLabelTopValueContainer">
                <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.UseForJustify">{{i18n.TRUE}}</div>
                <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.UseForJustify">{{i18n.FALSE}}</div>
            </div>
        </div>
    </aw-command-panel-section>
</aw-panel-body>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanelBody>
        <AwCommandPanelSection width="12" caption={i18n.overview} height="fill" collapsed="disabled">
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.displayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.DisplayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.color" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <AwRow height="1.3f" className="sw-property-val" color={AwParseService.instance( "'#'+props.subPanelContext.selection[0]._data.Color" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </AwRow>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.isstop" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.IsStop" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.IsStop" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.mustBeJustified" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.MustBeJustified" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.MustBeJustified" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <DivVisibleWhen className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly" visibleWhen={AwParseService.instance( "conditions.useForJustifyEnabled" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.useForJustify" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.UseForJustify" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.UseForJustify" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </DivVisibleWhen>
        </AwCommandPanelSection>
    </AwPanelBody>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;