// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import MomHomepage from 'viewmodel/MomHomepageViewModel';
import MomHomepageTile from 'viewmodel/MomHomepageTileViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const MomHomepageTileVisibleWhen = VisibleWhen(MomHomepageTile);
MomHomepageTileVisibleWhen.displayName = 'MomHomepageTileVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "ComposerIcon": "typeReportingTool",
        "Dashboards": "typePublishedDashboard",
        "origPage": "typeMachineParameter",
        "swacPage": "typeSWAC48",
        "ComposerIcon_": "homeDashboard",
        "ComposerVisibilityIcon": "typeComponentSecurityServices",
        "Dashboards_": "homeDashboard",
        "origPage_": "homeDashboard"
    },
    "dataProviders": {},
    "actions": {
        "redirectComposer": {
            "actionType": "Navigate",
            "navigateTo": "oPINRuntimeQCDashboardSources",
            "navigationParams": {}
        },
        "redirectDashboards": {
            "actionType": "Navigate",
            "navigateTo": "oPINRuntimeQCDashboardPubs",
            "navigationParams": {}
        },
        "redirectFirstPage": {
            "actionType": "Navigate",
            "navigateTo": "oPINROperationalDashboard",
            "navigationParams": {}
        },
        "redirectComposerVisibility": {
            "actionType": "Navigate",
            "navigateTo": "oPINRDashboardVisibility",
            "navigationParams": {}
        }
    },
    "lifecycleHooks": {},
    "onEvent": [
        {}
    ],
    "i18n": {
        "DashboardComposerVisibility": [
            "oPINRuntimeModuleMessage"
        ],
        "DashboardComposer": [
            "oPINRuntimeModuleMessage"
        ],
        "Dashboards": [
            "oPINRuntimeModuleMessage"
        ],
        "MPRuntime": [
            "oPINRuntimeModuleMessage"
        ],
        "swacWidget": [
            "oPINRuntimeModuleMessage"
        ],
        "operationalDashboards": [
            "oPINRuntimeModuleMessage"
        ]
    },
    "_viewModelId": "oPINRuntimeHome",
    "_uniqueViewModelId": "oPINRuntimeHome",
    "ctx": {
        "cognitoToken": {
            "type": "object"
        },
        "oPINProjectsConfig": {
            "type": "object"
        }
    }
};

    /*
    <mom-homepage>
    <div class="single-homepage-tile-section">
        <div class="mom-homepage-tile-section">
            <mom-homepage-tile visible-when="ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.MPDashboardContributor) !== -1 && (ctx.oPINProjectsConfig.enableQCEditor !== false)" header="i18n.DashboardComposer" icon="data.ComposerIcon" action="redirectComposer"></mom-homepage-tile>
            <mom-homepage-tile visible-when="ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.MPDashboardViewer) !== -1" header="i18n.Dashboards" icon="data.Dashboards" action="redirectDashboards"></mom-homepage-tile>
            <mom-homepage-tile visible-when="ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.MPDashboardContributor) !== -1" header="i18n.DashboardComposerVisibility" icon="data.ComposerVisibilityIcon" action="redirectComposerVisibility"></mom-homepage-tile>
            <mom-homepage-tile visible-when="ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf('MPRuntimeAccess') !== -1" header="i18n.MPRuntime" icon="data.origPage" action="redirectFirstPage"></mom-homepage-tile>
            <mom-homepage-tile visible-when="ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.WidgetCustomizationGroup) !== -1  && (ctx.oPINProjectsConfig.enableQCCustomWidget !== false)" header="i18n.swacWidget" icon="data.swacPage" action="redirectSwacPage"></mom-homepage-tile>
        </div>
    </div>
</mom-homepage>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <MomHomepage>
        <div className="single-homepage-tile-section">
            <div className="mom-homepage-tile-section">
                <MomHomepageTileVisibleWhen header={i18n.DashboardComposer} icon={AwParseService.instance( "data.ComposerIcon" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.redirectComposer} visibleWhen={AwParseService.instance( "ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.MPDashboardContributor) !== -1 && (ctx.oPINProjectsConfig.enableQCEditor !== false)" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MomHomepageTileVisibleWhen>
                <MomHomepageTileVisibleWhen header={i18n.Dashboards} icon={AwParseService.instance( "data.Dashboards" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.redirectDashboards} visibleWhen={AwParseService.instance( "ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.MPDashboardViewer) !== -1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MomHomepageTileVisibleWhen>
                <MomHomepageTileVisibleWhen header={i18n.DashboardComposerVisibility} icon={AwParseService.instance( "data.ComposerVisibilityIcon" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.redirectComposerVisibility} visibleWhen={AwParseService.instance( "ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.MPDashboardContributor) !== -1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MomHomepageTileVisibleWhen>
                <MomHomepageTileVisibleWhen header={i18n.MPRuntime} icon={AwParseService.instance( "data.origPage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.redirectFirstPage} visibleWhen={AwParseService.instance( "ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf('MPRuntimeAccess') !== -1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MomHomepageTileVisibleWhen>
                <MomHomepageTileVisibleWhen header={i18n.swacWidget} icon={AwParseService.instance( "data.swacPage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.redirectSwacPage} visibleWhen={AwParseService.instance( "ctx.cognitoToken.idToken.payload['cognito:groups'].indexOf(ctx.oPINProjectsConfig.oPINRuntime.WidgetCustomizationGroup) !== -1  && (ctx.oPINProjectsConfig.enableQCCustomWidget !== false)" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MomHomepageTileVisibleWhen>
            </div>
        </div>
    </MomHomepage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;