// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwRow from 'viewmodel/AwRowViewModel';
import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwGuidanceMessage from 'viewmodel/AwGuidanceMessageViewModel';
import MomHomepage from 'viewmodel/MomHomepageViewModel';
import MomHomepageCard from 'viewmodel/MomHomepageCardViewModel';
import AwI18n from 'viewmodel/AwI18nViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const AwScrollpanelVisibleWhen = VisibleWhen(AwScrollpanel);
const MomHomepageCardVisibleWhen = VisibleWhen(MomHomepageCard);
AwScrollpanelVisibleWhen.displayName = 'AwScrollpanelVisibleWhen';
MomHomepageCardVisibleWhen.displayName = 'MomHomepageCardVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/landingModule/landingService', () => import('js/landingModule/landingService'));
registerDynImportEntry('js/MomUtilsService', () => import('js/MomUtilsService'));
registerDynImportEntry('js/landingModule/feedbackManagerService', () => import('js/landingModule/feedbackManagerService'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {},
    "dataProviders": {
        "entityDPCardsInfo": {
            "dataProviderType": "static",
            "response": "{{ctx.cardsConfig.list}}",
            "totalFound": "{{ctx.cardsConfig.list.length}}"
        }
    },
    "actions": {
        "initializeList_post": {
            "actionType": "JSFunction",
            "method": "AddCardActions",
            "inputData": {
                "data": "{{data}}",
                "cardsList": "{{data.dataProviders.entityDPCardsInfo.viewModelCollection.loadedVMObjects}}"
            },
            "outputData": {
                "cardsData": "out"
            },
            "deps": "js/landingModule/landingService"
        },
        "initializeListComposite": {
            "actionType": "JSFunctionAsync",
            "method": "compositeAction",
            "deps": "js/MomUtilsService",
            "inputData": {
                "cfg": {
                    "vm": "{{data}}",
                    "action": "checkAuthorization",
                    "success": {
                        "action": "initializeList",
                        "success": {
                            "action": "getOperatingTime",
                            "success": {
                                "action": "sendFeedback"
                            }
                        }
                    }
                }
            }
        },
        "checkAuthorization": {
            "actionType": "JSFunction",
            "method": "areRolesAssigned",
            "inputData": {
                "roles": "{{ctx.cognitoToken}}"
            },
            "outputData": {
                "userIsAuthorized": "response"
            },
            "events": {
                "success": [
                    {
                        "name": "MPHome.authorized"
                    }
                ]
            },
            "deps": "js/landingModule/landingService"
        },
        "initializeList": {
            "actionType": "dataProvider",
            "method": "entityDPCardsInfo"
        },
        "getOperatingTime": {
            "actionType": "RESTService",
            "method": "GET",
            "inputData": {
                "request": {
                    "method": "GET",
                    "withCredentials": false,
                    "Content-Type": "application/json",
                    "url": "{{ctx.oPINProjectsConfig.oPINLanding.apiMiTenant}}api/cg/tenants/current"
                }
            },
            "outputData": {
                "startTimeUTC": "data.Context.ResourceOptimization.StartTimeUTC",
                "endTimeUTC": "data.Context.ResourceOptimization.EndTimeUTC",
                "isCostOptimizationEnabled": "data.Context.ResourceOptimization.IsEnabled",
                "tenantData": "data"
            },
            "events": {
                "success": [
                    {
                        "name": "landingHome.getLocalTime"
                    },
                    {
                        "name": "landingHome.setTenantData"
                    },
                    {
                        "name": "progress.end"
                    }
                ],
                "failure": [
                    {
                        "name": "progress.end"
                    }
                ]
            }
        },
        "sendFeedback": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initCLP",
                    "condition": "conditions.isDpeEnabled"
                },
                {
                    "action": "sendPageLoadToRefiner",
                    "condition": "conditions.isDpeEnabled"
                },
                {
                    "action": "showOpInFeedback",
                    "condition": "conditions.isDpeEnabled"
                }
            ]
        },
        "initCLP": {
            "actionType": "JSFunctionAsync",
            "method": "initCLP",
            "inputData": {},
            "deps": "js/landingModule/feedbackManagerService"
        },
        "sendPageLoadToRefiner": {
            "actionType": "JSFunction",
            "method": "sendFeedbackToCLP",
            "inputData": {
                "actionType": "trackEvent",
                "actionParam": "Homepageloadevent"
            },
            "deps": "js/landingModule/feedbackManagerService"
        },
        "showOpInFeedback": {
            "actionType": "JSFunction",
            "method": "sendFeedbackToCLP",
            "inputData": {
                "actionType": "showForm",
                "actionParam": "4d7b82c0-4057-11ee-bf5e-2357742f3332"
            },
            "deps": "js/landingModule/feedbackManagerService"
        },
        "getLocalTime": {
            "actionType": "JSFunction",
            "method": "getLocalTime",
            "inputData": {},
            "outputData": {
                "localTimeHHMM": "response"
            },
            "deps": "js/landingModule/landingService"
        },
        "setTenantData": {
            "actionType": "JSFunction",
            "method": "updateCtx",
            "inputData": {
                "context": "ocinTenantData",
                "data": "{{data.tenantData}}"
            },
            "deps": "js/appCtxService"
        },
        "drillDown_0": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 0,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_1": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 1,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_2": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 2,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_3": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 3,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_4": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 4,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_5": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 5,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_6": {
            "actionType": "JSFunction",
            "method": "navigateTo",
            "inputData": {
                "state": "momSwacSublocation",
                "params": {
                    "screen": "workCalendarCards"
                }
            },
            "deps": "js/MomUtilsService"
        },
        "drillDown_7": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 7,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_8": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 8,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        },
        "drillDown_9": {
            "actionType": "JSFunctionAsync",
            "method": "changeLocation",
            "inputData": {
                "urlIndex": 9,
                "cardsList": "{{ctx.cardsConfig.list}}"
            },
            "deps": "js/landingModule/landingService"
        }
    },
    "lifecycleHooks": {
        "onMount": "initializeListComposite"
    },
    "conditions": {
        "isDpeEnabled": {
            "expression": "ctx.enableTraceDPE === true"
        },
        "showOperatingTimeMessage": {
            "expression": "(data.startTimeUTC !== undefined || data.startTimeUTC !== null) && (data.endTimeUTC !== undefined || data.endTimeUTC!== null) && (data.isCostOptimizationEnabled == true) && (data.localTimeHHMM < data.startTimeUTC || data.localTimeHHMM > data.endTimeUTC)"
        }
    },
    "messages": {
        "userNotHavingRoles": {
            "messageType": "WARNING",
            "messageText": "{{i18n.userRolesNotAssigned}}",
            "messageTextParams": [
                "{{ctx.user.fullName}}"
            ],
            "navigationOptions": [
                {
                    "option": "Ok",
                    "text": "{{i18n.ok}}",
                    "action": ""
                }
            ]
        },
        "operatingTimeMessage": {
            "messageType": "WARNING",
            "messageText": "{{i18n.operatingTimeMessage}}",
            "messageTextParams": [
                "{{data.startTimeUTC}}",
                "{{data.endTimeUTC}}"
            ]
        }
    },
    "onEvent": [
        {
            "eventId": "entityDPCardsInfo.modelObjectsUpdated",
            "action": "initializeList_post"
        },
        {
            "eventId": "landingHome.getLocalTime",
            "action": "getLocalTime"
        },
        {
            "eventId": "landingHome.setTenantData",
            "action": "setTenantData"
        },
        {
            "eventId": "MPHome.authorized",
            "message": "userNotHavingRoles",
            "condition": "!data.userIsAuthorized"
        }
    ],
    "i18n": {
        "title_0": [
            "cardsMessages"
        ],
        "longDesc_0": [
            "cardsMessages"
        ],
        "title_1": [
            "cardsMessages"
        ],
        "longDesc_1": [
            "cardsMessages"
        ],
        "title_2": [
            "cardsMessages"
        ],
        "longDesc_2": [
            "cardsMessages"
        ],
        "title_3": [
            "cardsMessages"
        ],
        "longDesc_3": [
            "cardsMessages"
        ],
        "title_4": [
            "cardsMessages"
        ],
        "longDesc_4": [
            "cardsMessages"
        ],
        "title_5": [
            "cardsMessages"
        ],
        "longDesc_5": [
            "cardsMessages"
        ],
        "title_6": [
            "cardsMessages"
        ],
        "longDesc_6": [
            "cardsMessages"
        ],
        "title_7": [
            "cardsMessages"
        ],
        "longDesc_7": [
            "cardsMessages"
        ],
        "title_8": [
            "cardsMessages"
        ],
        "longDesc_8": [
            "cardsMessages"
        ],
        "title_9": [
            "cardsMessages"
        ],
        "longDesc_9": [
            "cardsMessages"
        ],
        "userRolesNotAssigned": [
            "landingMessages"
        ],
        "operatingTimeMessage": [
            "landingMessages"
        ],
        "ok": [
            "landingMessages"
        ]
    },
    "_viewModelId": "landingHome",
    "_uniqueViewModelId": "landingHome",
    "ctx": {
        "cardsConfig": {
            "type": "object"
        },
        "cognitoToken": {
            "type": "object"
        },
        "oPINProjectsConfig": {
            "type": "object"
        },
        "enableTraceDPE": {
            "type": "object"
        },
        "user": {
            "type": "object"
        }
    }
};

    /*
    <aw-row height="1"></aw-row>

<aw-row height="1" style="text-align: center;">
	<aw-scrollpanel visible-when ="conditions.showOperatingTimeMessage" style="display: inline-block;">
		<aw-guidance-message message="operatingTimeMessage" closable="false" banner-style="false" show-icon="true" icon="indicatorInformationWarning16" show-type="false">
        </aw-guidance-message>
	</aw-scrollpanel>
</aw-row>
<aw-row height="fill" style="text-align: center;">
    <mom-homepage>
        <mom-homepage-card aw-repeat="item:data.cardsData" header="item.title"
            image="item.image" action="item.realAction" visible-when="item.isVisible" title="{{item.title}}">
            <aw-i18n title="{{item.title}}">{{item.longDesc}}</aw-i18n>
        </mom-homepage-card>
    </mom-homepage>
</aw-row>
<aw-row height="1"></aw-row>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <AwRow height="1">
    </AwRow>
    <AwRow height="1" style={{textAlign: 'center'}}>
        <AwScrollpanelVisibleWhen style={{display: 'inline-block'}} visibleWhen={AwParseService.instance( "conditions.showOperatingTimeMessage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwGuidanceMessage message={messages.operatingTimeMessage} closable="false" bannerStyle="false" showIcon="true" icon="indicatorInformationWarning16" showType="false">
            </AwGuidanceMessage>
        </AwScrollpanelVisibleWhen>
    </AwRow>
    <AwRow height="fill" style={{textAlign: 'center'}}>
        <MomHomepage>
            { Object.entries( AwParseService.instance( "data.cardsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                <MomHomepageCardVisibleWhen header={AwParseService.instance( "item.title" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} image={AwParseService.instance( "item.image" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} action={AwParseService.instance( "item.realAction" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} title={AwParseService.instance( "item.title" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} visibleWhen={AwParseService.instance( "item.isVisible" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                    <AwI18n title={AwParseService.instance( "item.title" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )}>
                        {AwParseService.instance( "item.longDesc" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )}
                    </AwI18n>
                </MomHomepageCardVisibleWhen>
            ) ) }
        </MomHomepage>
    </AwRow>
    <AwRow height="1">
    </AwRow>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;